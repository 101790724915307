
<template>
  <div class="noAccessOuterWrapper">
    <div class="noAccessInnerWrapper">
      <img
        src="https://wpsiteplan.com/wp-content/uploads//Depositphotos_182326142_xl-2015-scaled-e1577466564954.jpg"
        alt="alt"
      >
      <h3>Please check your mail box to verify your account</h3>
      <p>We have sent a verification email to <strong>{{ userEmail }}</strong>. In order to start using our platform, please verify your account </p>
      <span>Already verified?</span>
      <button
        class="loginBtn"
        @click="$router.push(`/login?email=${userEmail}`)"
      >
        Click here to Login
      </button>
    </div>
  </div>
</template>
<script>

  export default {
    props: {},
    data: () => ({ userEmail: '' }),

    created () {
      if (this.$route && this.$route.query && this.$route.query.email) {
        this.userEmail = this.$route.query.email
      } else {
        this.$router.push('/login')
      }
    },

    asyncComputed: {},

    methods: {

    },
  }

</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
  .noAccessOuterWrapper {
    width: 100%;
    min-height: 100vh;
    background-color: rgb(0, 94, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    .noAccessInnerWrapper {
      background-color: white;
      font-family: 'Noto Sans KR', sans-serif;
      border-radius: 8px;
      box-shadow: 8px 8px 28px rgba(0,0,0,0.2);
      width: 600px;
      min-height: 60vh;
      padding: 4rem 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 120px;
        height: 120px;
        border-radius: 120px;
        margin-bottom: 3rem;
      }
      h3 {
        font-size: 1.3rem;
        text-align: center;
        margin-bottom: 2rem;
      }
      p {
        text-align: center;
        margin-bottom: 3rem;
        font-size: 1.1rem;
      }
      button {
        margin-top: 18px;
        background-color: #4D7AF7;
        font-size: 1rem;
        color: white;
        box-shadow: 8px 8px 28px rgba(0,0,0,0.2);
        transition: 0.3s!important;
        padding: 8px 38px;
        &:hover {
          transition: 0.3s!important;
          box-shadow: 8px 8px 28px rgba(0, 0, 0, 0.095);
          background-color: rgb(59, 103, 224);
        }
      }
    }
  }
</style>
